import withRoot from "../../utils/withRoot";
import React from "react";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";
import Page from "../../components/Page";
import List from "../../components/List";

const Dailyword = props => {
  const dailyword = props.data.allMarkdownRemark.edges;
  return (
    <Page title="每日一词">
      <SEO title="每日一词" />
      <List items={dailyword} />
    </Page>
  );
};

export const query = graphql`
  query DailywordQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/posts/dailyword/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            imageurl
            title
            path
          }
        }
      }
    }
  }
`;

export default withRoot(Dailyword);
